var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{attrs:{"type":"button"},on:{"click":function($event){_vm.hideTitles = !_vm.hideTitles}}},[(_vm.hideTitles)?_c('span',[_vm._v("Show More Titles")]):_vm._e(),(!_vm.hideTitles)?_c('span',[_vm._v("Show Less Titles")]):_vm._e()]),(!_vm.hideTitles)?_c('div',[(_vm.blogs !== '0 results')?_c('div',{staticClass:"blog-box-multi"},[_c('div',[_c('form',[_c('div',[_c('label',{attrs:{"for":"search"}},[_vm._v("Search Title(s) "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search-input",class:this.search.length < 3 ? 'invalid' : 'entered',attrs:{"type":"text","name":"search","id":"search"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])]),_c('button',{attrs:{"type":"button","disabled":_vm.isDisabled},on:{"click":function($event){return _vm.handleClearSearch()}}},[_vm._v(" Clear Search ")])])]),_vm._l((_vm.filteredBlogs),function(blog,index){return _c('div',{key:blog.id,staticClass:"item",on:{"click":function($event){return _vm.handleShowBlog(index)}}},[_c('h3',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.matchName(blog.heading))}})]),_c('p',{domProps:{"innerHTML":_vm._s(blog.message.slice(0, 80) + '...')}}),_c('div',{staticClass:"blog-box-multi-footer"},[_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(blog.name))])])])})],2):_vm._e()]):_vm._e(),(_vm.hideTitles)?_c('div',{staticClass:"blog-box"},[_c('div',[(_vm.filteredBlogs[_vm.index] !== undefined)?_c('h1',[_vm._v(" "+_vm._s(_vm.filteredBlogs[_vm.index].heading)+" ")]):_vm._e(),(_vm.filteredBlogs[_vm.index] !== undefined)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.filteredBlogs[_vm.index].message)}}):_vm._e(),(
          _vm.filteredBlogs[_vm.index] !== undefined &&
          _vm.filteredBlogs[_vm.index].admin === '1'
        )?_c('div',{staticClass:"adminMessage"},[_c('i',{staticClass:"fas fa-exclamation"}),_vm._v(" editied by ADMIN. ")]):_vm._e(),_c('hr'),(_vm.filteredBlogs[_vm.index] !== undefined)?_c('div',{staticClass:"blog-footer-wrapper"},[_c('p',[_vm._v(" "+_vm._s(_vm.timeStamp(_vm.filteredBlogs[_vm.index].posted, 'YYYYMMDD, h:mm:ss a'))+" ")]),_c('p',[_vm._v("Post by "+_vm._s(_vm.filteredBlogs[_vm.index].name))])]):_vm._e(),_c('div',{staticClass:"clap-wrapper"},[_c('div',[(_vm.filteredBlogs[_vm.index] !== undefined)?_c('span',{staticClass:"likes",on:{"click":function($event){return _vm.handleLikes(
                _vm.filteredBlogs[_vm.index].id,
                _vm.filteredBlogs[_vm.index].name,
                _vm.filteredBlogs[_vm.index].heading,
                _vm.filteredBlogs[_vm.index].message,
                _vm.filteredBlogs[_vm.index].likes
              )}}},[_c('i',{staticClass:"far fa-thumbs-up"}),_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(_vm.filteredBlogs[_vm.index].likes))])]):_vm._e()]),_c('div',[(_vm.filteredBlogs[_vm.index] !== undefined)?_c('span',{staticClass:"dislikes",on:{"click":function($event){return _vm.handleDislikes(
                _vm.filteredBlogs[_vm.index].id,
                _vm.filteredBlogs[_vm.index].name,
                _vm.filteredBlogs[_vm.index].heading,
                _vm.filteredBlogs[_vm.index].message,
                _vm.filteredBlogs[_vm.index].dislikes
              )}}},[_c('i',{staticClass:"far fa-thumbs-down"}),_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(_vm.filteredBlogs[_vm.index].dislikes))])]):_vm._e()])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }